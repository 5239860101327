import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Form, Button, Navbar, Nav } from "react-bootstrap";
 
 import PentReg from "./Register_MPWDs/PentReg";


function App() {
 
  return( 
    <>
    {/* <FormRegister /> */}
   
    <Router>
        <Routes>
        <Route path='/index' element={ <PentReg  />} />
        <Route path='/' element={ <PentReg  />} />
        <Route path='/*' element={ <PentReg  />} />
    
        </Routes>
      </Router>
 
    
    </>
  )
}
export default App;


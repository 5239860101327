import React, { useState, useEffect } from "react";
import axios from 'axios';
import "./Pent.css";
import { Link, Outlet } from "react-router-dom";
import mpdws from "./mpwds1.jpg";

import Swal from 'sweetalert2';

import dlogo from '../Pent_register/noimage.png';

import { Form, Button } from 'react-bootstrap';

import { api } from '../Api';
function PentReg() {

  
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState("")
  const [Price, setPrice] = useState("")
  const [Quantity, setQuantity] = useState("")
  // const [gender, setGender] = useState("");
  const [cordinator, setCordinator] = useState("");
  const [churchArea, setChurchArea] = useState("");
  const [district, setDistrict] = useState("");
  const [local, setLocal] = useState("");
  const [Color, setColor] = useState("");
  const [address, setAddress] = useState("");
  const [Size, setSize] = useState("");
  const [occupation, setOccupation] = useState("");
  const [Prices, setPrices] = useState("");

  const [showOtherChurchArea, setShowOtherChurchArea] = useState(false);

  const [otherChurchArea, setOtherChurchArea] = useState("");

  const [totalPrice, setTotalPrice] = useState(0);


  const [image, setImage] = useState(null);
  
  const [base64Image, setBase64Image] = useState('');

  // Add a function to calculate total price
  const calculateTotalPrice = () => {
    if (Price && Quantity) {
      const total = parseInt(Price) * parseInt(Quantity);
      setTotalPrice(total);
    } else {
      setTotalPrice(0);
    }
  };

  // Update total price whenever Price or Quantity changes
  useEffect(() => {
    calculateTotalPrice();
  }, [Price, Quantity]);



  const handleFileInputChange = (e) => {
    const selectedImage = e.target.files[0];
    
    if (selectedImage) {
      if (selectedImage.size > 400 * 1024) {
        // File size is larger than 400KB, clear the input
        e.target.value = '';
        setImage(null);
        // alert('File size should be no larger than 400KB');
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-right',
            iconColor: 'red',
          customClass: {
              popup: 'colored-toast'
          },
          showConfirmButton: false,
          timer: 5500,
          timerProgressBar: true
      })
      Toast.fire({
          type: "error",
          icon: 'error',
          color: "red",
        //   background: 'red',
          title: 'File size should be no larger than 400KB ',
          // text: 'Something went wrong!', email and password must be filled out
      })
 
        return;
      }

      const imageURL = URL.createObjectURL(selectedImage);
      setImage(imageURL);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
    }
  };





   

  // const nextStep = () => {
  //   if (!isFormValid()) {
  //     return("Please fill out all fields before proceeding.");
  //     // return;
  //   }
  //   setCurrentStep(currentStep + 1);
  // };



  const getErrorMessage = () => {
    if (currentStep === 1) {
      if (!firstName || !/^[a-zA-Z0-9\s\-_:]+$/.test(firstName)) {
        return "Invalid first name.";
      }
    
      if (!lastName || !/^[a-zA-Z\s\-_:]+$/.test(lastName)) {
        return "Invalid last name.";
      }
 

    if (!phoneNumber || !/^\+?[\d\s\-]+$/.test(phoneNumber)) {
      return "Phone number validation error.";
    }
  
  }

  if (currentStep === 2) {
 
  if (!churchArea || !/^[a-zA-Z0-9\s\-_:]+$/.test(churchArea)) {
    return "Invalid Church Area";
  }
  if (!district || !/^[a-zA-Z0-9\s\-_:]+$/.test(district)) {
    return "Invalid Church  district .";
  }
  if (!local || !/^[a-zA-Z0-9\s\-_:]+$/.test(local)) {
    return "Invalid Church Local.";
  }

  if (!cordinator  || !/^[a-zA-Z0-9\s\-_:]+$/.test(cordinator))  {
    return "Invalid Cordinator ";
  }

}  

    if (currentStep === 3 && (!Price || !Quantity || !Color || !Size )) {
      return false;
    }
    // return true;

    return null;  // No errors found
};

const isFormValid = () => {
    return getErrorMessage() === null;
};


  const nextStep = () => {
    const errorMessage = getErrorMessage();
    if (errorMessage) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'red',
            customClass: {
                popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 5500,
            timerProgressBar: true
        });
        Toast.fire({
            type: "error",
            icon: 'error',
            title: errorMessage,
        });

        return;
    }
    setCurrentStep(currentStep + 1);
};
 
 


  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  

  const handleToast = (icon, title, color) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: color,
      customClass: {
        popup: 'colored-toast'
      },
      showConfirmButton: false,
      timer: 5500,
      timerProgressBar: true
    });
  
    Toast.fire({ icon, title });
  }
  

 
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (! phoneNumber || !firstName) {
      setLoading(false);
      handleToast('error', 'Form validation error!', 'red');
    } else {
      const data = {
        firstName,
        cordinator,
        lastName,
        email,
        phoneNumber, 
        churchArea: showOtherChurchArea ? otherChurchArea : churchArea,
        district,
        local, 
         Color,
        Quantity,
        Price,
        Size,
        base64Image,
      };
  
      api.post('/api/registermpwds', data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Authorization': 'Bearer YOUR_TOKEN' // if needed
        }
      })
      .then(res => {
        if (res && res.data && res.data.message) {
          handleToast('success', res.data.message, 'green');
          setImage(/* Your dlogo or any other image */);
          setLoading(false);
          setTimeout(() => { window.location = "/thankyou"; }, 7000);
        } else if (res && res.response && res.response.data && res.response.data.response && res.response.data.response.message) {
          setLoading(false);
          handleToast('error', 'Error: ' + res.response.data.response.message, 'red');
        } else {
          setLoading(false);
          handleToast('error', 'Error: An unknown error occurred', 'red');
        }
      })
      .catch(err => {
        setLoading(false);


 
          setLoading(false);
        
          // First, fix the typo: change 'errponse' to 'response'
          if (err && err.response && err.response.data && err.response.data.message) {
            handleToast('error', 'Error: ' + err.response.data.message, 'red');
          } else {
            handleToast('error', 'Error:  ', 'red' + err.response.data.message);
          }
        
        


        // const errorMessage = err.response?.data?.message || 'An error occurred';
        // handleToast('error', 'Error: ' + errorMessage, 'red');
      });
    }

    // setLoading(true);
  }






 

  return (
    <>

      {loading ? (
  <div class="containers"> 
    <div   class="loading"></div></div>

            ) : (<></>)}

      {/* ---------------Navigation------------ */}
      <div>
        <div className='horizontal-nav'>
          <nav className="navbar navbar-expand-lg">
            <div id="nx-nav" className="container">
              <i style={{ color: "white" }} class='bx bx-menu bx-lg' className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                {/* <span className="navbar-toggler-icon"></span> */}
                <i class='bx bx-menu bx-lg'></i>
              </i>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/">Home</Link>
                  </li>
                  <li id='nav-li-id' >
                    <Link id='log-link' to="/"> <a href="####"><img src={mpdws} alt="logo" id='logo' /></a></Link>
                  </li>
                </ul>


                {/* ---------------------- */}
                <div className="collapse navbar-collapse" id="Second">
                  <ul className='navbar-nav'>
                    <li className='nav-item'>
                      <Link to="/registermpwds" className='nav-link'><a href="####">Register</a></Link>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </nav>

          <Outlet />
        </div>
      </div>

      {/* ---------------Navigation------------ */}
      {/* ================================================================================== */}
      &nbsp;
      &nbsp;
      <div id="#signup-sec" className="container-fluid">


        <form onSubmit={handleSubmit}>
          {/* Step 1 */}
          {currentStep === 1 && (
            <>
              <div className="form-group">
              
                <span id="h1" >Online Registration Portal for MPWDs T-Shirt / Lacoste Orders. </span>
                {/* =========================================================== */}
                <div id='imgUpload'>
                  <div>
                    <div>


                        <div id='imgUpload'>
                    <div>
                      <div>
                      <img width="100" height="100" id="previewImg"
                       src={image || dlogo} alt='copitguild'
                       class="img-responsive rounded-circle center-block d-block mx-auto"
                        onError={(e) => { e.target.onerror = null; e.target.src = dlogo }}
                       />
                                                        
          <input type="file" id="image" onChange={handleFileInputChange} />
                      </div>
                    </div>
                  </div>
                    </div>
                  </div>
                </div>

                {/* =========================================================== */}
                <label>
                  First Name:
                  <div id="fn">
                    <i style={{ marginTop: "16px" }} class='bx bx-user bx-sm '></i>
                    <input type="text" value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className={!firstName ? "error" : ""}
                      class="form-control"
                      required />
                  </div>

                </label>
                {/* ========================================================= */}
                <label>
                  Last Name:
                  <div id="fn">
                    <i style={{ marginTop: "16px" }} class='bx bxs-user bx-sm '></i>
                    <input type="text" value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className={!lastName ? "error" : ""}
                      class="form-control" required />
                  </div>
                </label>

                {/* ================================================ */}
                <label>
                  Email (optional):

                  <div id="fn">
                    <i style={{ marginTop: "16px" }} class='bx bxs-envelope bx-sm' ></i>
                    <input type="email" value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email here"

                      class="form-control" />
                  </div>
                </label>
                {/* =========================================================== */}

                {/* ============================================================================== */}
                <label>
                  Phone Number:
                  <div id="fn">
                    <i style={{ marginTop: "16px" }} class='bx bxs-phone bx-sm'></i>
                    <input type="tel" value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className={!phoneNumber ? "error" : ""}
                      class="form-control" required />
                  </div>
                </label>

                {/* ========================================================================== */}


              </div>

              <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>
            </>
          )}

          {/* ========================================================================================= */}
          {/* Step 2 */}
          {currentStep === 2 && (
            <>
              <h1>Step 2</h1>
             
 
              <div>
                <label>
                  Select a Church Area:
                  <div id="fn">
                  <i style={{marginTop:"16px"}}  class='bx bx-grid bx-sm'></i>
                    <select class="form-control" value={churchArea} onChange={(e) => {
                      setChurchArea(e.target.value);
                      if (e.target.value === "Others") {
                        setShowOtherChurchArea(true);
                      } else {
                        setShowOtherChurchArea(false);
                        setOtherChurchArea(""); // Clear the input field value
                      }
                    }}>
                      <option value="">--Select--</option>
                      <option value="Achimota">Achimota</option>
                      <option value="Abuakwa" >Abuakwa</option>
                      <option value="Afram-Plains" >Afram-Plains</option>
                      <option value="Aflao">Aflao</option>
                      <option value="Agona-Swedru">Agona-Swedru</option>
                      <option value="Agormanya">Agormanya</option>
                      <option value="Akim-Oda">Akim-Oda</option>
                      <option value="Anyaa-Ablekuma">Anyaa-Ablekuma</option>
                      <option value="Asankrangwa">Asankrangwa</option>
                      <option value="Ashaiman">Ashaiman</option>
                      <option value="Asante-Bekwai">Asante-Bekwai</option>
                      <option value="Asokwa">Asokwa</option>
                      <option value="Assin-Fosu">Assin-Fosu</option>
                      <option value="Atebubu">Atebubu</option>
                      <option value="Atonsu">Atonsu</option>
                      <option value="Axim">Axim</option>
                      <option value="Bawku">Bawku</option>
                      <option value="Berekum">Berekum</option>
                      <option value="Bogoso">Bogoso</option>
                      <option value="Bolgatanga">Bolgatanga</option>
                      <option value="Bompata">Bompata</option>
                      <option value="Bompata-Sector">Bompata-Sector</option>
                      <option value="Capecoast">Capecoast</option>
                      <option value="Capecoast-Sector">Capecoast-Sector</option>
                      <option value="Dansoman">Dansoman</option>
                      <option value="Downtown-Ofankor">Downtown-Ofankor</option>
                      <option value="Downtown-Ofaakor">Downtown-Ofaakor</option>
                      <option value="Duayaw-Nkwanta">Duayaw-Nkwanta</option>
                      <option value="Duankwa" uankwa>Duankwa</option>
                      <option value="Ejisu">Ejisu</option>
                      <option value="Essam-Debiso">Essam-Debiso</option>
                      <option value="Goaso">Goaso</option>
                      <option value="Haatso">Haatso</option>
                      <option value="Ho">Ho</option>
                      <option value="Hohoe">Hohoe</option>
                      <option value="Juaboso">Juaboso</option>
                      <option value="Kade">Kade</option>
                      <option value="Kaneshie">Kaneshie</option>
                      <option value="Kaneshie-Sector">Kaneshie-Sector</option>
                      <option value="Kasoa">Kasoa</option>
                      <option value="Kete-Krachi">Kete-Krachi</option>
                      <option value="Kintampo">Kintampo</option>
                      <option value="Koforidua">Koforidua</option>
                      <option value="Koforidua">Koforidua-Effiduase</option>
                      <option value="Konongo">Konongo</option>
                      <option value="Kwadaso">Kwadaso</option>
                      <option value="Kwadaso-Sector">Kwadaso-Sector</option>
                      <option value="La">La</option>
                      <option value="Lower-Volta-Sector">Lower-Volta-Sector</option>
                      <option value="Madina">Madina</option>
                      <option value="Madina-Sector">Madina-Sector</option>
                      <option value="Mampong">Mampong</option>
                      <option value="Mampong-Sector">Mampong-Sector</option>
                      <option value="Mankessim-Sector">Mankessim-Sector</option>
                      <option value="Mankessim">Mankessim</option>
                      <option value="New-Tafo">New-Tafo</option>
                      <option value="Nkawkaw">Nkawkaw</option>
                      <option value="Northern-sector">Northern-sector</option>
                      <option value="Nsawam">Nsawam</option>
                      <option value="Obuasi">Obuasi</option>
                      <option value="Odorkor">Odorkor</option>
                      <option value="Offinso">Offinso</option>
                      <option value="Old-Tafo">Old-Tafo</option>
                      <option value="Pensa-Knust">Pensa-Knust</option>
                      <option value="Pensa-Legon">Pensa-Legon</option>
                      <option value="Pensa-Ucc ">Pensa-Ucc </option>
                      <option value="Pensa-Uew">Pensa-Uew </option>
                      <option value="Sawla">Sawla</option>
                      <option value="Sefwi-Bekwai">Sefwi-Bekwai</option>
                      <option value="Sefwi-Juabuso">Sefwi-Juabuso </option>
                      <option value="Sefwi-Wiawso">Sefwi-Wiawso</option>
                      <option value="Sekondi">Sekondi</option>
                      <option value="Sogakope">Sogakope</option>
                      <option value="Suame">Suame</option>
                      <option value="Suhum">Suhum</option>
                      <option value="Suhum-Sector">Suhum-Sector</option>
                      <option value="Sunyani">Sunyani</option>
                      <option value="Takoradi">Takoradi</option>
                      <option value="Takoradi-Sector">Takoradi-Sector</option>
                      <option value="Techiman">Techiman</option>
                      <option value="Techiman-Sector">Techiman-Sector"</option>
                      <option value="Tema">Tema</option>
                      <option value="Tepa">Tepa</option>
                      <option value="Teshie-Nungua">Teshie-Nungua</option>
                      <option value="Tumu">Tumu</option>
                      <option value="Twifi-Praso">Twifi-Praso</option>
                      <option value="Upper-East-Sector">Upper-East-Sector</option>
                      <option value="Upper-West-Sector">Upper-West-Sector</option>
                      <option value="Upper-Volta-Sector">Upper-Volta-Sector</option>
                      <option value="Wa">Wa</option>
                      <option value="Walewale">Walewale</option>
                      <option value="Winneba">Winneba</option>
                      <option value="Yendi">Yendi</option>

                      <option value="Others">Others</option>
                    </select>
                  </div>
                </label>
                {showOtherChurchArea && (

                  <label>

                    Other Church Area:
                    <div id="fn">
                      <i style={{ marginTop: "16px" }} class='bx bx-book-open bx-sm'></i>
                      <input
                        type="text"
                        value={otherChurchArea}
                        onChange={(e) => setOtherChurchArea(e.target.value)}
                        className={!otherChurchArea ? "error" : ""}
                        class="form-control"
                        required
                      />
                    </div>
                  </label>
                )}


                <div>
                  <label>
                    District:
                    <input type="text" className="form-control"
                      placeholder="Enter your district"
                      value={district} onChange={(e) => setDistrict(e.target.value)} />
                  </label>
                </div>
                <div>
                  <label>
                    Local:
                    <input type="text" className="form-control"
                      placeholder="Enter your Local" value={local}
                      onChange={(e) => setLocal(e.target.value)} />
                  </label>
                </div>

                <label>
                Are you a Cordinator ? 
                <select value={cordinator} onChange={(e) => setCordinator(e.target.value)}>
                  <option value="">--Select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </label>
            


              </div>

              <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>
              <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>

            </>
          )
          }

          {/* Step 3 */}
          {currentStep === 3 && (
            <>


              <div className="form-group">
                <h1>Finish up</h1>
                  {/* ================Color================ */}
              <label>
                Choose T-shirt / Lacoste Color
                <select value={Color} onChange={(e) => setColor(e.target.value)}>
                  <option value="">--Select--</option>
                  <option value="Wine">Wine</option>
                  <option value="Gray">Gray</option>
                  <option value="White">White</option>
                  <option value="Blue">Blue</option>
                  <option value="Black">Black</option>
                </select>
              </label>


              {/* ===================================== */}
              <label>
                Choose T-shirt / Lacoste size: 
                <select value={Size} onChange={(e) => setSize(e.target.value)}>
                  <option value="">---Select----</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                </select>
              </label>

                <label>
                  Prices
                  <div id="fn-disable">
                    <select value={Price} onChange={(e) => setPrice(e.target.value)}>
                    <option value="">--Select--</option>
                      <option value="40">T-Shirt : 40Cedis</option>
                      <option value="55">Lacoste (high Grammage): 55 cedis</option>
                      <option value="50">lacoste (Normal Grammage : 50 cedis)</option>
                    </select>
                  </div>

                </label>

                <label>
                  Quantity:
                  <input type="text"
                    class="form-control"
                    className={!Quantity ? "error" : ""}
                    placeholder="Enter your Quantity"
                    value={Quantity} onChange={(e) => setQuantity(e.target.value)} />
                </label>
 
   {/* Add a section to display total price */}
   {currentStep === 3 && totalPrice > 0 && (
        <div className="form-group">
          <h3>Total Price: {totalPrice} Cedis</h3>
        </div>
      )}
              </div>
              <button type="button" className="btn btn-secondary" onClick={prevStep}>Previous</button>
              <button type="submit" className="btn btn-primary" disabled={!isFormValid()} >Submit</button>
            </>

          )}
        </form >
      </div >



    </>

  );
}

export default PentReg;
